<template>
    <div class="content" style="display: flex;
  flex-direction: column;
  min-height: 100vh;">
        <main style="flex: 1">
            <div style="min-height: 100vh" class="position-relative">
                <div class="navbar navbar-expand pr-0 navbar-light bg-white navbar-shadow"
                     id="default-navbar"
                     data-primary>

                    <div class="container py-2">
                        <router-link to="/">
                            <img
                                src="@/assets/images/home.png"
                                alt="logo"
                                class="img-fluid logo-img my-auto"/>
                        </router-link>
                    </div>

                </div>
                <SpinnerXl/>
                <div class="container mt-5" id="page">


                    <div class="py-32pt navbar-submenu">
                        <div class="container page__container">
                            <div class="progression-bar progression-bar--active-primary">
                                <div class="progression-bar__item"
                                     :class="{'progression-bar__item--complete progression-bar__item--complete': progress > 0,
                                    'progression-bar__item--active': progress === 0
                            }"
                                >
                                <span class="progression-bar__item-content" @click="progress=0">
                                    <i class="material-icons progression-bar__item-icon" v-if="progress > 0">done</i>
                                    <i class="material-icons progression-bar__item-icon" v-else></i>
                                    <span
                                        class="progression-bar__item-text h5 mb-0 text-uppercase">Dados Pessoais</span>
                                </span>
                                </div>
                                <div
                                    class="progression-bar__item"
                                    :class="{'progression-bar__item--complete': progress > 1,
                                    'progression-bar__item--active progression-bar__item--complete': progress === 1
                            }"
                                >
                                <span class="progression-bar__item-content" @click="progress=1">
                                    <i class="material-icons progression-bar__item-icon" v-if="progress > 1">done</i>
                                    <i class="material-icons progression-bar__item-icon" v-else></i>
                                    <span
                                        class="progression-bar__item-text h5 mb-0 text-uppercase">Plano</span>
                                </span>
                                </div>
                                <div class="progression-bar__item"
                                     :class="{'progression-bar__item--active progression-bar__item--complete': progress === 2}"
                                >
                                <span class="progression-bar__item-content" @click="progress=2">
                                    <i class="material-icons progression-bar__item-icon"></i>
                                    <span
                                        class="progression-bar__item-text h5 mb-0 text-uppercase">Detalhes do Pagamento</span>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="navbar-submenu py-32pt navbar-shadow my-5" v-if="finished">
                        <div class="col text-center my-5 f-100">
                            <i class="fa fa-check-circle text-success" style="font-size: 40px"></i>
                        </div>
                        <div class="col text-center mb-5">
                            <h3>Cadastro realizado com sucesso!</h3>
                            <p>Você irá receber em seu e-mail de cadastro um link para cadastrar sua senha.</p>
                        </div>
                    </div>


                    <div class="page-section container page__container" v-else style="min-height: 100vh">

                        <!--            Step dados pessoais-->
                        <div class="col-lg-10 p-0 mx-auto" v-show="progress === 0">
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <label class="form-label" for="name">Nome Completo:</label>
                                    <input id="name" type="text" class="form-control" :disabled="disabled"
                                           v-model="form.name"
                                           placeholder="Nome completo ...">
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label class="form-label" for="email">E-mail:</label>
                                    <input id="email" type="email" class="form-control" :disabled="disabled"
                                           v-model="form.email"
                                           placeholder="seu_email@email.com">
                                </div>
                                <div class="col-md-4 mb-3">
                                    <label class="form-label" for="mobilePhone">Telefone:</label>
                                    <input id="mobilePhone" type="text" class="form-control" v-model="form.mobilePhone"
                                           placeholder="(__) _____-____"
                                           v-maska="['(##) ####-####', '(##) #####-####']">
                                </div>

                                <div class="col-md-4 mb-3">
                                    <label class="form-label" for="cpf">CPF/CNPJ:</label>
                                    <input id="cpf" type="text" class="form-control" v-model="form.cpf"
                                           placeholder="CPF/CNPJ" v-maska="['###.###.###-##', '##.###.###/####-##']">
                                </div>

                                <!--                    <div class="col-md-6 mb-3">-->
                                <!--                        <label class="form-label" for="birthDate">Data de Nascimento:</label>-->
                                <!--                        <input id="birthDate" type="date" class="form-control" v-model="form.birthDate"-->
                                <!--                               placeholder="">-->
                                <!--                    </div>-->

                                <div class="col-md-4 mb-3">
                                    <label class="form-label" for="postalCode">CEP:</label>
                                    <input id="postalCode" type="text" class="form-control" v-model="form.postalCode"
                                           placeholder="CEP" v-maska="'##.###-###'" @keyup="setAddress">
                                </div>

                                <div class="col-md-1 mb-3">
                                    <label class="form-label" for="uf">UF:</label>
                                    <input id="uf" type="text" class="form-control" v-model="form.uf"
                                           placeholder="UF">
                                </div>

                                <div class="col-md-3 mb-3">
                                    <label class="form-label" for="city">Cidade:</label>
                                    <input id="city" type="text" class="form-control" v-model="form.city"
                                           placeholder="Cidade">
                                </div>

                                <div class="col-md-4 mb-3">
                                    <label class="form-label" for="address">Endereço:</label>
                                    <input id="address" type="text" class="form-control" v-model="form.address"
                                           placeholder="Endereço">
                                </div>

                                <div class="col-md-4 mb-3">
                                    <label class="form-label" for="province">Bairro:</label>
                                    <input id="province" type="text" class="form-control" v-model="form.province"
                                           placeholder="Bairro">
                                </div>

                                <div class="col-md-5 mb-3">
                                    <label class="form-label" for="complement">Complemento:</label>
                                    <input id="complement" type="text" class="form-control" v-model="form.complement"
                                           placeholder="Complemento">
                                </div>

                                <div class="col-md-2 mb-3">
                                    <label class="form-label" for="addressNumber">Número:</label>
                                    <input id="addressNumber" type="text" class="form-control"
                                           v-model="form.addressNumber"
                                           placeholder="Número">
                                </div>

                                <!--                    <div class="col-md-6 mb-3">-->
                                <!--                        <label class="form-label" for="incomeValue">Renda Mensal:</label>-->
                                <!--                        <input id="incomeValue" type="text" class="form-control" v-model="form.incomeValue"-->
                                <!--                               placeholder="Renda Mensal">-->
                                <!--                    </div>-->

                            </div>
                        </div>

                        <!--            Step Planos-->
                        <div class="container page__container" v-show="progress === 1">
                            <div class="row card-group-row mb-lg-40pt justify-content-center">
                                <div class="col-lg-4 col-sm-6 card-group-row__col pointer" v-for="(plan, i) in plans"
                                     :key="`card-plan-${plan.id}`">

                                    <div
                                        class="card card-group-row__card text-center o-hidden card--raised position-relative"
                                        :class="{'border-primary': form.cycle === plan.type,
                                        'border-0': form.cycle !== plan.type,
                                }"
                                        @click="form.cycle = plan.type; form.plan = plan.id; form.planIndex=i"
                                    >
                                        <i class="fa fa-check-circle position-absolute text-primary"
                                           v-if="form.cycle === plan.type"
                                           style="right: 10px; top: 10px"></i>


                                        <div class="card-body d-flex flex-column">
                                            <div class="flex-grow mb-16pt">
                                                <!--                                            <span-->
                                                <!--                                                class="w-64 h-64 icon-holder rounded-circle d-inline-flex mb-16pt"-->
                                                <!--                                                :class="{-->
                                                <!--                                                    'icon-holder&#45;&#45;outline-primary text-primary': form.cycle === plan.type,-->
                                                <!--                                                    'icon-holder&#45;&#45;outline-secondary': form.cycle !== plan.type,-->
                                                <!--                                                }"-->
                                                <!--                                            >-->
                                                <!--                                                <i class="material-icons">person</i>-->
                                                <!--                                            </span>-->
                                                <h4 class="mb-8pt">{{ plan.title }}</h4>
                                                <p class="text-70 mb-0">
                                                    <span v-html="plan.description"
                                                          class="plan-area"
                                                          style="list-style: none!important;"></span>
                                                </p>
                                            </div>
                                            <p class="d-flex justify-content-center align-items-center m-0">
                                        <span
                                            class="h1 m-0 font-weight-normal ml-2">{{
                                                String(plan.value.toLocaleString('pt-br', {
                                                    style: 'currency',
                                                    currency: 'BRL'
                                                }))
                                            }}
                                        </span>

                                            </p>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <!--                    <div class="page-headline page-headline&#45;&#45;white page-headline&#45;&#45;title text-center">-->
                            <!--                        <h2 class="">All plans include</h2>-->
                            <!--                    </div>-->

                            <!--                    <div class="col-lg-8 mx-auto">-->
                            <!--                        <div class="row">-->
                            <!--                            <div class="col-sm-6 mb-24pt mb-sm-0">-->
                            <!--                                <ul class="list-unstyled">-->
                            <!--                                    <li class="d-flex align-items-center mb-8pt">-->
                            <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                            <!--                                        <span class="-70">24h Access to PRO Courses</span>-->
                            <!--                                    </li>-->
                            <!--                                    <li class="d-flex align-items-center mb-8pt">-->
                            <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                            <!--                                        <span class="-70">Join 2000+ Community Members</span>-->
                            <!--                                    </li>-->
                            <!--                                    <li class="d-flex align-items-center mb-8pt">-->
                            <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                            <!--                                        <span class="-70">Access to New Courses Weekly</span>-->
                            <!--                                    </li>-->
                            <!--                                    <li class="d-flex align-items-center mb-8pt">-->
                            <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                            <!--                                        <span class="-70">Support from our Tutors</span>-->
                            <!--                                    </li>-->
                            <!--                                    <li class="d-flex align-items-center">-->
                            <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                            <!--                                        <span class="-70">Assets Included per Course</span>-->
                            <!--                                    </li>-->
                            <!--                                </ul>-->
                            <!--                            </div>-->
                            <!--                            <div class="col-sm-6">-->
                            <!--                                <ul class="list-unstyled">-->
                            <!--                                    <li class="d-flex align-items-center mb-8pt">-->
                            <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                            <!--                                        <span class="-70">24h Access to PRO Courses</span>-->
                            <!--                                    </li>-->
                            <!--                                    <li class="d-flex align-items-center mb-8pt">-->
                            <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                            <!--                                        <span class="-70">Join 2000+ Community Members</span>-->
                            <!--                                    </li>-->
                            <!--                                    <li class="d-flex align-items-center mb-8pt">-->
                            <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                            <!--                                        <span class="-70">Access to New Courses Weekly</span>-->
                            <!--                                    </li>-->
                            <!--                                    <li class="d-flex align-items-center mb-8pt">-->
                            <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                            <!--                                        <span class="-70">Support from our Tutors</span>-->
                            <!--                                    </li>-->
                            <!--                                    <li class="d-flex align-items-center">-->
                            <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                            <!--                                        <span class="-70">Assets Included per Course</span>-->
                            <!--                                    </li>-->
                            <!--                                </ul>-->
                            <!--                            </div>-->
                            <!--                        </div>-->
                            <!--                    </div>-->

                        </div>

                        <!--            Step de Pagamento-->
                        <div class="col-lg-10 p-0 mx-auto" v-show="progress === 2">
                            <div class="row">
                                <div class="col-md-6">
                                    <h3>Informações do Cartão de Crédito</h3>
                                    <div class="row">

                                        <div class="mb-3 col-12">
                                            <label class="form-label" for="holderName">Nome do Titular do
                                                Cartão:</label>
                                            <input id="holderName" type="text" class="form-control"
                                                   v-model="form.holderName"
                                                   placeholder="Nome do Titular do Cartão">
                                        </div>

                                        <div class="mb-3 col-12">
                                            <label class="form-label" for="number">Número do Cartão:</label>
                                            <input id="number" type="text" class="form-control" v-model="form.number"
                                                   placeholder="Número do Cartão" v-maska="'#### #### #### ####'">
                                        </div>

                                        <div class="mb-3 col-md-6">
                                            <label class="form-label" for="expiration">Validade:</label>
                                            <input id="expiration" type="text" class="form-control"
                                                   v-model="form.expiration"
                                                   placeholder="mm/AAAA" v-maska="'##/####'">
                                        </div>

                                        <div class="mb-3 col-md-6">
                                            <label class="form-label" for="ccv">CVV:</label>
                                            <input id="ccv" type="text" class="form-control" v-model="form.ccv"
                                                   placeholder="CVV" v-maska="'###'">
                                        </div>

                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="card mb-0">
                                        <div class="card-body">
                                            <h5>Resumo da compra</h5>
                                            <div class="d-flex mb-8pt">
                                                <div class="flex"><strong class="text-70">Plano</strong></div>
                                                <strong
                                                    v-if="plans.length > 0">{{ plans[form.planIndex].title }}</strong>
                                            </div>

                                            <div class="alert alert-soft-warning">
                                                <div class="d-flex flex-wrap align-items-start">
                                                    <div class="mr-8pt">
                                                        <i class="material-icons">check</i>
                                                    </div>
                                                    <div class="flex" style="min-width: 180px">
                                                        <small v-if="plans.length > 0" class="text-100">
                                                            <div v-html="plans[form.planIndex].description" class="plan-area"></div>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="d-flex mb-16pt pb-16pt border-bottom">
                                                <div class="flex"><strong class="text-70">Valor</strong></div>
                                                <strong v-if="plans.length > 0">R$
                                                    {{
                                                        String(plans[form.planIndex].value.toFixed(2)).replace('.', ',')
                                                    }}</strong>
                                            </div>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="mr-1 mb-0" checked id="topic-all">
                                                <label>Termos e condições</label>
                                                <small class="form-text text-muted">Ao marcar aqui e continuar, concordo
                                                    com os
                                                    Termos de Uso da Lummefy</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col text-center mt-5">
                                <button type="button" class="mx-1 btn btn-outline-danger" v-if="progress > 0"
                                        @click="--progress">
                                    Voltar
                                </button>
                                <button type="button" class="mx-1 btn btn-outline-primary" v-if="progress < 2"
                                        @click="validateStep">
                                    Próximo
                                </button>
                                <button type="button" class="mx-1 btn btn-outline-success" v-if="progress === 2"
                                        @click="save">
                                    Confirmar
                                </button>
                            </div>
                        </div>
                    </div>


                </div>


            </div>
        </main>

        <div class="bg-white border-top-2 mt-auto w-100" id="footer">
            <div class="px-5 page-section d-flex flex-column">
                <b-row>
                    <b-col col sm="12" class="mt-auto text-center">
                        {{ new Date().getFullYear() }} © <a href="https://www.innovareti.com.br/"
                                                            class="pointer text-link text-end mt-auto"
                                                            target="_blank"><b>Innovare TI</b></a>.
                    </b-col>
                </b-row>
            </div>

        </div>
    </div>
</template>

<script>
import {toFormData, ValidateForm} from "@/components/composables/cruds";
import {endLoading, startLoading} from "@/components/composables/spinners";
import http from '@/http'
import env from "@/env";
import store from "@/store";
import SpinnerXl from "@/components/base/spinner-xl.vue";
import {cpf, cnpj} from 'cpf-cnpj-validator';
import {removeCpfCnpjMask} from "@/components/composables/masks";

export default {
    components: {
        SpinnerXl
    },
    data() {
        return {
            form: {
                name: '',
                email: '',
                mobilePhone: '',
                cpf: '',
                postalCode: '',
                // incomeValue: '',
                birthDate: '',
                address: '',
                addressNumber: '',
                province: '',
                uf: '',
                city: '',
                complement: '',
                billingType: 'CREDIT_CARD',
                holderName: '',
                number: '',
                expiration: '',
                ccv: '',
                cycle: '',
                plan: '',
                planIndex: 0
            },
            progress: 0,
            finished: false,
            disabled: false,
            plans: []
        }
    },
    methods: {
        async save() {
            startLoading('page', 'save');
            this.$store.commit('api/setSpinner', true);

            const required = {
                name: 'Nome Completo',
                email: 'E-mail',
                mobilePhone: 'Telefone',
                cpf: 'CPF/CNPJ',
                // birthDate: 'Data de Nascimento',
                postalCode: 'CEP',
                uf: 'UF',
                city: 'Cidade',
                address: 'Endereço',
                province: 'Bairro',
                addressNumber: 'sn',
                // incomeValue: 'Renda Mensal',
                holderName: 'Nome do Titular do Cartão',
                number: 'Número do Cartão',
                expiration: 'Expiração',
                ccv: 'Código de Segurança',
            }

            if (!ValidateForm(this.$root, this.form, required)) {
                this.$store.commit('api/setSpinner', false);
                endLoading('page', 'save');
                return;
            }

            let newFormData = toFormData(this.form);

            newFormData.append('cpf', removeCpfCnpjMask(this.form.cpf));

            http.post('mentor', newFormData)
                .then(async response => {
                    const token = this.$route.query.token;
                    if (token) {
                        const currentUser = store.state.auth.user;
                        await this.$store.commit('auth/setCurrentUser', {
                            ...currentUser,
                            plan: this.form.plan
                        });
                        await this.$router.push('/home');
                    } else {
                        this.$notifySuccess(response.data);
                        this.finished = true;
                    }
                })
                .catch(e => {
                    console.error('Checkout Mentor: ', e)
                    this.$notifyError(e);
                })
                .finally(() => {
                    endLoading('page', 'save');
                    this.$store.commit('api/setSpinner', false);
                })
        },

        async setAddress() {
            var cep = this.form.postalCode.replace(/\D/g, '');
            var validacep = /^[0-9]{8}$/;
            if (validacep.test(cep)) {
                http.get("https://viacep.com.br/ws/" + cep + "/json/?callback=?")
                    .then(response => {
                        let json = response.data.replace('?(', '').replace(');', '');
                        json = JSON.parse(json)

                        this.form.uf = json.uf;
                        this.form.city = json.localidade;
                        this.form.province = json.bairro;
                        this.form.address = json.logradouro;
                        this.form.complement = json.complemento;

                    })
            }
        },

        validateStep() {
            let required = {};
            if (this.progress === 0) {
                required = {
                    name: 'Nome Completo',
                    email: 'E-mail',
                    mobilePhone: 'Telefone',
                    cpf: 'CPF/CNPJ',
                    // birthDate: 'Data de Nascimento',
                    postalCode: 'CEP',
                    uf: 'UF',
                    city: 'Cidade',
                    address: 'Endereço',
                    province: 'Bairro',
                    addressNumber: 'Número',
                    // incomeValue: 'Renda Mensal',
                }
            } else if (this.progress === 2) {
                required = {
                    holderName: 'Nome do Titular do Cartão',
                    number: 'Número do Cartão',
                    expiration: 'Expiração',
                    ccv: 'Código de Segurança',
                }
            }

            if (!ValidateForm(this.$root, this.form, required)) {
                return;
            }

            if (this.progress === 0 && this.form.cpf.length < 18) {
                if (!cpf.isValid(this.form.cpf)) {
                    this.$notifyError('CPF inválido!');
                    return;
                }
            } else if (this.progress === 0 && this.form.cpf.length === 18) {
                if (!cnpj.isValid(this.form.cpf)) {
                    this.$notifyError('CNPJ inválido!');
                    return;
                }
            }


            ++this.progress;
        },

        getUser(token) {
            return http.post(env.api + 'mentors/me', {
                    token: token
                })
                .then(response => {
                    this.form.name = response.data.name;
                    this.form.email = response.data.email;
                    this.form.id = response.data.id;
                    this.disabled = true;
                })
                .catch(error => {
                    console.error(error)
                    localStorage.removeItem('token');
                });
        },

        getPlans() {
            http.get('plans/list?paginated=false')
                .then(response => {
                    this.plans = response.data.data
                })
        }
    },

    mounted() {
        const token = this.$route.query.token;
        if (token) this.getUser(token);
        this.getPlans();
    }
}
</script>

<style>

.plan-area > ul.benifits {

list-style-type: none !important;
padding-left: 0px !important;

}

</style>

<style scoped>


.mt-5 {
    margin-top: 3rem;
}


</style>
